import '../styles/events.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Events = ({ setNotOptions, mobile }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-title' : 'events-title';

  return (
    <section className='events-container'>
      <h1 className={titleStyle}>EVENTS</h1>
      <div className='events-box'>
        <p className='lorem-ipsum'>
          <br />
          There are currently no events planned. <br />
          <br />
          {/* Please{' '}
          <Link to='/Register'>
            <span className='email-links' onClick={() => scrollToTop()}>
              register{' '}
            </span>
          </Link>{' '}
          with us to receive email alerts about new and upcoming events. */}
          <br />
          <br />
        </p>
      </div>
    </section>
  );
};

export default Events;
