import '../styles/contact.css';
import { useEffect } from 'react';
import graphic from '../images/mobile-supplement-graphic.webp';

const Contact = ({ setNotOptions, mobile }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const contactStyle = mobile ? 'mobile-contact' : 'desktop-contact';

  return (
    <section className='contact-container'>
      <div className='contact-box-container' id='top-contact-box'>
        <h1 className='contact-title'>CONTACT</h1>
        <div className='contact-box'>
          <p className='lorem-ipsum'>
            <br />
            For commercial enquiries, please contact us at:
            <br />
            <br />
            <a href='mailto:commercial@aesthetics-masterclass.com'>
              <span className='email-links'>
                {' '}
                commercial@aesthetics-masterclass.com
              </span>
            </a>
            <br />
            <br />
            <br />
            To submit an article to Aesthetics Masterclass, please contact us at:
            <br />
            <br />
            <a href='mailto:submissions@woundmasterclass.com'>
              <span className='email-links'>
                {' '}
                submissions@aesthetics-masterclass.com
              </span>
            </a>
            <br />
            <br /> Alternatively, contact the editor at: <br />
            <br />
            <a href='mailto:editor@aesthetics-masterclass.com'>
              <span className='email-links'> editor@aesthetics-masterclass.com</span>
            </a>
            <br />
            <br />
          </p>
        </div>
      </div>
      <div className='contact-box-container'>
        <h1 className='contact-title'>OTHER ENQUIRIES OR HELP</h1>
        <div className='contact-box'>
          <p className='lorem-ipsum'>
            <br />
            For technical help, if you have problems with your account, or if
            you have any questions about our privacy policy, cookies policy or
            terms and conditions, please contact us at: <br />
            <br />
            <a href='mailto:admin@aesthetics-masterclass.com'>
              <span className='email-links'>admin@aesthetics-masterclass.com</span>
            </a>
            <br />
            <br />
            <br />
            For other enquiries relating to the journal please contact the
            editor at:
            <br />
            <br />
            <a href='mailto:editor@aesthetics-masterclass.com'>
              <span className='email-links'> editor@aesthetics-masterclass.com</span>
            </a>
          </p>
        </div>
      </div>
      {/* <div className='page-graphic-container' id={contactStyle}>
        <h2 className='mobile-content-titles'>
          <span>UPCOMING CONTENT</span>
        </h2>
        <div className='page-graphic-box'>
          <img src={graphic} className='page-graphic'></img>
        </div>
      </div> */}
    </section>
  );
};

export default Contact;
