import { useEffect, useState } from 'react';

import axios from 'axios';

const Unsubscribe = () => {
  const [value, setValue] = useState('');
  const [unsubscribeAction, setUnsubscribeAction] = useState('');
  const [unsubscribed, setUnsubscribed] = useState('');
  useEffect(() => {
    if (unsubscribeAction) {
      axios
        .post('https://wmcapi.herokuapp.com/api/unsubscribed_wmc', { email: value })
        .then((response) => {
          setUnsubscribeAction(false);
          setUnsubscribed(true);
        })
        .catch((err) => {
          setUnsubscribeAction(false);
        });
    }
  }, [unsubscribeAction]);
  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };
  const changeHandler = (e) => {
    setValue(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setTimeout(() => setUnsubscribeAction(true), 1000);
  };
  
  if (!unsubscribed) { 
    return (
    <section className='login-container'>
      <div className='login-title' style={{ textAlign: 'center' }}>
        ENTER EMAIL TO UNSUBSCRIBE
      </div>
      <div className='login-box'>
        <form className='login-form' id='login' onSubmit={submitHandler}>
          <div>
            <input
              required
              id='login-form-top-input'
              type='text'
              className='login-input'
              placeholder='Email'
              spellCheck='false'
              maxLength='50'
              onChange={changeHandler}
              value={value}
            ></input>
          </div>
        </form>
      </div>
      
        <button className='form-button' form='login' >
          CONTINUE
        </button>
      
    </section>
  );
          } else if (unsubscribed) {
            return <div className='error-message'>
            <div className='message-box'>
              <p>
                You have been unsubscribed. Sorry to see you go!
              </p>
            </div>
          </div>
          }
};

export default Unsubscribe;
