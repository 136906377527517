import '../styles/home.css';
import issue from '../images/aesthetics-cover.webp';
import Swiper from './Swiper';
import { wmcApi } from '../utils/api';
import FileDownload from 'js-file-download';
// import TwitterFeed from './TwitterFeed';
import { Link } from 'react-router-dom';
import firstissue from '../images/website-first-issue-image.webp';
import ewma_ad from '../images/ewma-ad.webp';
import ewma_ad_small from '../images/ewma-ad-small.webp';
import { useState, useEffect } from 'react';

const Home = ({ accessToken, setNotOptions, auth }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const [ewmaSize, setEwmaSize] = useState(ewma_ad_small);

  useEffect(() => {
    if (window.innerWidth > 1480) {
      setEwmaSize(ewma_ad);
    }
  }, []);

  const handleDownload = () => {
    wmcApi
      .get('/download', {
        url: 'https://wmcapi.herokuapp.com/api/download',
        responseType: 'blob',
        headers: {
          authorization: accessToken,
        },
      })
      .then((response) => {
        FileDownload(response.data, 'testimg.png');
      });
  };

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const handleResize = () => {
    if (window.innerWidth > 1480) {
      setEwmaSize(ewma_ad);
    } else if (window.innerWidth < 1480) {
      setEwmaSize(ewma_ad_small);
    }
  };

  window.addEventListener('resize', () => {
    handleResize();
  });

  if (!auth) {
    return (
      <section className='page-container'>
        <div className='home-ewma-ad-container'>
          <Link to='/Register'>
            {/* <img src={firstissue} className='offer-home' alt='register offer'></img> */}
          </Link>
        </div>
        <section className='home-container'>
          <div className='current-issue-container'>
            <h1 className='content-titles'>UPCOMING ISSUE</h1>

            <div className='current-issue-box'>
              <img
              src={issue}
              className='issue-img'
              alt='Wound Masterclass issue'
              // onClick={() => {
              //   handleDownload();
              // }}
            ></img>

              <span id='about-issue'>
                <br />
                <p>
                  Upcoming issue.
                  <br />
                  <br />
                  Read more about the journal{' '}
                  <Link to='/About'>
                    <span
                      className='email-links'
                      onClick={() => {
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      here.
                    </span>
                  </Link>
                  <br />
                  <br />
                  {/* See our international{' '}
                  <Link to='/About'>
                    <span
                      className='email-links'
                      onClick={() => {
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      editorial board.
                    </span>
                  </Link> */}
                  <br />
                  <br />
                  {/* Please{' '}
                <Link to='/Register'>
                  <span className='email-links' onClick={() => scrollToTop()}>
                    register{' '}
                  </span>
                </Link>
                with us to get free, full access to the journal. */}
                  <br />
                  <br />
                </p>
              </span>
            </div>
          </div>
          <div className='featured-content-container'>
            {/* <h2 className='content-titles'>CONTENT THIS ISSUE</h2> */}
            {/* <div className='featured-content-box'> */}
            {/* <Swiper  /> */}
            {/* </div> */}
          </div>
        </section>
        {/* <div className='offer-twitter'>
        <div className='twitter-container'>
          <div className='twitter-box'>
            <TwitterFeed />
          </div>
        </div>
      </div> */}
      </section>
    );
  } else {
    return (
      <section className='page-container'>
        <div className='home-ewma-ad-container'>
          <Link to='/Register'>
            <img
              src={firstissue}
              className='offer-home'
              alt='register offer'
            ></img>
          </Link>
        </div>
        <section className='home-container'>
          <div className='current-issue-container'>
            <h1 className='content-titles'>CURRENT ISSUE (MAY - JUNE 2022)</h1>

            <div className='current-issue-box'>
              <div
                style={{
                  position: 'relative',
                  paddingTop: 'max(60%,326px)',
                  height: 0,
                  width: '100%',
                }}
              >
                <iframe
                  allow='clipboard-write'
                  sandbox='allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox'
                  allowFullScreen='true'
                  style={{
                    position: 'absolute',
                    border: 'none',
                    width: '100%',
                    height: '100%',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  }}
                  src='https://e.issuu.com/embed.html?backgroundColor=%23000000&d=wound-masterclass-june-2022&hideIssuuLogo=true&hideShareButton=true&u=woundmasterclass'
                />
              </div>
            </div>
          </div>
          <div className='featured-content-container'>
            <h2 className='content-titles'>CONTENT THIS ISSUE</h2>
            <div className='featured-content-box'>{/* <Swiper  /> */}</div>
          </div>
        </section>
        {/* <div className='offer-twitter'>
            <div className='twitter-container'>
              <div className='twitter-box'>
                <TwitterFeed />
              </div>
            </div>
          </div> */}
      </section>
    );
  }
};

export default Home;
