import '../styles/articles.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Articles = ({ setNotOptions, mobile }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-title' : 'events-title';

  return (
    <section className='articles-container'>
      <h1 className={titleStyle}>ARTICLES</h1>
      <div className='articles-box'>
        {/* <p className='lorem-ipsum'>
          <br />
          The first issue of Aesthetics Masterclass will be available soon.<br />
          <br />
          Please{' '}
          <Link to='/Register'>
            <span className='email-links' onClick={() => scrollToTop()}>
              register{' '}
            </span>
          </Link>{' '}
          with us to get full access, and receive news and updates via email.
          <br />
          <br />
        </p> */}
      </div>
    </section>
  );
};

export default Articles;
