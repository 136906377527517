import '../styles/issues.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Issues = ({ setNotOptions, mobile }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-title' : 'events-title';

  return (
    <section className='issues-container'>
      <h1 className={titleStyle}>ISSUES</h1>
      <div className='issues-box'>
        <p className='lorem-ipsum'>
          <br />
          {/* Our first issue is now available for our registered readers.<br /> */}
          <br />
          {/* Please{' '}
          <Link to='/Register'>
            <span className='email-links' onClick={() => scrollToTop()}>
              register{' '}
            </span>
          </Link>{' '}
          with us to get full access, and receive news and updates via email. */}
          <br />
          <br />
        </p>
      </div>
    </section>
  );
};

export default Issues;
