import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Header from './components/Header';
import MobileHome from './components/MobileHome';
import MobileHeader from './components/MobileHeader';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Subscribe from './components/Subscribe';
import About from './components/About';
import Issues from './components/Issues';
import Articles from './components/Articles';
import Events from './components/Events';
import Contact from './components/Contact';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiesPolicy from './components/CookiesPolicy';
import Accounts from './components/Accounts';
import Unsubscribe from './components/Unsubscribe'
import { isMobile } from 'react-device-detect';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import CookieConsent from 'react-cookie-consent';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const App = () => {
  const [mobile, setMobile] = useState(false);
  const [mobileLandscape, setMobileLandscape] = useState(false);
  const [auth, setAuth] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [loginPage, setLoginPage] = useState(false);
  const [registerPage, setRegisterPage] = useState(false);
  const [subscribePage, setSubscribePage] = useState(false);
  const [notOptions, setNotOptions] = useState(false);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userPostalCode, setUserPostalCode] = useState('');
  const [secureLogin, setSecureLogin] = useState(false);
  const [secureSession, setSecureSession] = useState(false);

  const firebaseConfig = {
    apiKey: 'AIzaSyDJnfinqXejXobadfIwNCujrsU6pED7WTw',
    authDomain: 'wound-masterclass.firebaseapp.com',
    projectId: 'wound-masterclass',
    storageBucket: 'wound-masterclass.appspot.com',
    messagingSenderId: '533477309659',
    appId: '1:533477309659:web:681ab2f6bf1d9104a120fe',
    measurementId: 'G-G3EE6FFP62',
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  useEffect(() => {
    if (window.innerHeight > window.innerWidth || isMobile) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    const user = Cookies.get('user');
    if (user) {
      setAuth(true);
      setUserFirstName(user);
    } else {
      setAuth(false);
    }
  }, []);

  const [loggedInMsg, setLoggedInMsg] = useState(false);
  setTimeout(() => setLoggedInMsg(true), 1000);

  const handleResize = () => {
    if (window.innerHeight > window.innerWidth) {
      setMobile(true);
      setMobileLandscape(false);
    } else if (window.innerWidth < 1200) {
      setMobile(true);
    } else if (window.innerHeight < window.innerWidth && isMobile) {
      setMobile(true);
      setMobileLandscape(true);
    } else if (window.innerHeight < window.innerWidth && !isMobile) {
      setMobile(false);
    }
  };

  window.addEventListener('resize', () => {
    handleResize();
  });

  return (
    <>
      <CookieConsent
        location={'top'}
        buttonText={'Confirm'}
        style={{ background: '#708090' }}
        buttonStyle={{
          background: '#000000',
          color: '#ffffff',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        This website is for healthcare professionals. Please only continue to
        use our website if you are a healthcare professional. If you are a
        healthcare professional, please click confirm.
      </CookieConsent>
      <CookieConsent
        location={'bottom'}
        style={{ background: '#708090' }}
        buttonStyle={{
          background: '#000000',
          color: '#ffffff',
          fontSize: '14px',
          fontWeight: 700,
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>

      <div className='divider'></div>

      <Router>
        <div className='body-div'>
          {mobile ? (
            <MobileHeader
              notOptions={notOptions}
              auth={auth}
              setAuth={setAuth}
              loginPage={loginPage}
              setLoginPage={setLoginPage}
              registerPage={registerPage}
              setRegisterPage={setRegisterPage}
              subscribePage={subscribePage}
              setSubscribePage={setSubscribePage}
              userFirstName={userFirstName}
              secureLogin={secureLogin}
              setSecureLogin={setSecureLogin}
              secureSession={secureSession}
              setSecureSession={setSecureSession}
            />
          ) : (
            <Header
              notOptions={notOptions}
              auth={auth}
              setAuth={setAuth}
              loginPage={loginPage}
              setLoginPage={setLoginPage}
              registerPage={registerPage}
              setRegisterPage={setRegisterPage}
              subscribePage={subscribePage}
              setSubscribePage={setSubscribePage}
              userFirstName={userFirstName}
              secureLogin={secureLogin}
              setSecureLogin={setSecureLogin}
              secureSession={secureSession}
              setSecureSession={setSecureSession}
            />
          )}
          <Routes>
            <Route
              exact
              path='/'
              element={
                !mobile ? (
                  <Home
                    accessToken={accessToken}
                    setNotOptions={setNotOptions}
                    auth={auth}
                  />
                ) : (
                  <MobileHome
                    accessToken={accessToken}
                    setNotOptions={setNotOptions}
                    mobileLandscape={mobileLandscape}
                    setMobileLandscape={setMobileLandscape}
                    auth={auth}
                  />
                )
              }
            ></Route>
            <Route
              exact
              path='/Home'
              element={
                !mobile ? (
                  <Home
                    accessToken={accessToken}
                    setNotOptions={setNotOptions}
                    auth={auth}
                  />
                ) : (
                  <MobileHome
                    setNotOptions={setNotOptions}
                    mobileLandscape={mobileLandscape}
                    setMobileLandscape={setMobileLandscape}
                    auth={auth}
                  />
                )
              }
            ></Route>

            <Route
              exact
              path='/About'
              element={<About setNotOptions={setNotOptions} mobile={mobile} />}
            ></Route>
            <Route
              exact
              path='/Issues'
              element={<Issues setNotOptions={setNotOptions} mobile={mobile} />}
            ></Route>
            <Route
              exact
              path='/Articles'
              element={
                <Articles setNotOptions={setNotOptions} mobile={mobile} />
              }
            ></Route>
            <Route
              exact
              path='/Events'
              element={<Events setNotOptions={setNotOptions} mobile={mobile} />}
            ></Route>

            <Route
              exact
              path='/Contact'
              element={
                <Contact setNotOptions={setNotOptions} mobile={mobile} />
              }
            ></Route>
             <Route
              exact
              path='/Unsubscribe'
              element={
                <Unsubscribe setNotOptions={setNotOptions} mobile={mobile} />
              }
            ></Route>
            <Route
              exact
              path='/Login'
              element={
                <Login
                  accessToken={accessToken}
                  setAccessToken={setAccessToken}
                  auth={auth}
                  setAuth={setAuth}
                  loginPage={loginPage}
                  setLoginPage={setLoginPage}
                  registerPage={registerPage}
                  setRegisterPage={setRegisterPage}
                  subscribePage={subscribePage}
                  setSubscribePage={setSubscribePage}
                  setNotOptions={setNotOptions}
                  setUserFirstName={setUserFirstName}
                  loggedInMsg={loggedInMsg}
                  setLoggedInMsg={setLoggedInMsg}
                  mobile={mobile}
                  userFirstName={userFirstName}
                  userLastName={userLastName}
                  setUserLastName={setUserLastName}
                  userEmail={userEmail}
                  setUserEmail={setUserEmail}
                  userRole={userRole}
                  setUserRole={setUserRole}
                  userPostalCode={userPostalCode}
                  setUserPostalCode={setUserPostalCode}
                  secureLogin={secureLogin}
                  setSecureLogin={setSecureLogin}
                  secureSession={secureSession}
                  setSecureSession={setSecureSession}
                />
              }
            ></Route>
            <Route
              exact
              path='/Register'
              element={
                <Register
                  auth={auth}
                  setAuth={setAuth}
                  mobile={mobile}
                  setAccessToken={setAccessToken}
                  loginPage={loginPage}
                  setLoginPage={setLoginPage}
                  registerPage={registerPage}
                  setRegisterPage={setRegisterPage}
                  subscribePage={subscribePage}
                  setSubscribePage={setSubscribePage}
                  setNotOptions={setNotOptions}
                  userFirstName={userFirstName}
                  setUserFirstName={setUserFirstName}
                  loggedInMsg={loggedInMsg}
                  setLoggedInMsg={setLoggedInMsg}
                  userLastName={userLastName}
                  setUserLastName={setUserLastName}
                  userEmail={userEmail}
                  setUserEmail={setUserEmail}
                  userRole={userRole}
                  setUserRole={setUserRole}
                  userPostalCode={userPostalCode}
                  setUserPostalCode={setUserPostalCode}
                />
              }
            ></Route>
            <Route
              exact
              path='/Subscribe'
              element={
                <Subscribe
                  mobile={mobile}
                  loginPage={loginPage}
                  setLoginPage={setLoginPage}
                  registerPage={registerPage}
                  setRegisterPage={setRegisterPage}
                  subscribePage={subscribePage}
                  setSubscribePage={setSubscribePage}
                  setNotOptions={setNotOptions}
                />
              }
            ></Route>
            <Route
              exact
              path='/TermsAndConditions'
              element={<TermsAndConditions setNotOptions={setNotOptions} />}
            ></Route>
            <Route
              exact
              path='/PrivacyPolicy'
              element={<PrivacyPolicy setNotOptions={setNotOptions} />}
            ></Route>
            <Route
              exact
              path='/CookiesPolicy'
              element={<CookiesPolicy setNotOptions={setNotOptions} />}
            ></Route>
            <Route
              exact
              path='/Accounts'
              element={
                <Accounts
                  setNotOptions={setNotOptions}
                  auth={auth}
                  setAuth={setAuth}
                  userFirstName={userFirstName}
                  setUserFirstName={setUserFirstName}
                  userLastName={userLastName}
                  setUserLastName={setUserLastName}
                  userEmail={userEmail}
                  setUserEmail={setUserEmail}
                  userRole={userRole}
                  setUserRole={setUserRole}
                  userPostalCode={userPostalCode}
                  setUserPostalCode={setUserPostalCode}
                  secureLogin={secureLogin}
                  setSecureLogin={setSecureLogin}
                  secureSession={secureSession}
                  setSecureSession={setSecureSession}
                />
              }
            ></Route>
          </Routes>
        </div>

        <section className='tagline'>
          <span>
            Better aesthetics. Better content. Better clinical articles. Get
            accredited.
          </span>
        </section>
        <Footer mobile={mobile} />
      </Router>
    </>
  );
};

export default App;
