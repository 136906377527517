import '../styles/mobile-home.css';
import issue from '../images/aesthetics-cover.webp';
import SwiperMobile from './SwiperMobile'
// import TwitterFeed from '../components/TwitterFeed';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import offer from '../images/register-offer.webp';
import offer_mobile from '../images/register-offer-mobile.webp';
import ewma from '../images/ewma-ad-small.webp';
import ewma_ad from '../images/ewma-ad.webp';
import ewma_ad_small from '../images/ewma-ad-small.webp';

const MobileHome = ({ setNotOptions, mobileLandscape, setMobileLandscape, auth }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const [ewmaSize, setEwmaSize] = useState(ewma_ad_small);

  const handleResize = () => {
    if (window.innerWidth > 1070) {
      setEwmaSize(ewma_ad);
    } else if (window.innerWidth < 1070) {
      setEwmaSize(ewma_ad_small);
    }
  };

  window.addEventListener('resize', () => {
    handleResize();
  });

  if (!auth) {
  return (
    <>
      <section className='mobile-home-container'>
        {/* <a href='https://ewma.org/' target='_blank'>
          <img
            src={ewmaSize}
            className='ewma-ad-mobile'
            alt='ewma conference ad'
          ></img>
        </a> */}
        <div className='mobile-current-issue-container'>
          <h1 className='mobile-content-titles'>
            <span>UPCOMING ISSUE</span>
          </h1>
          <div className='mobile-current-issue-box'>
            <img
              src={issue}
              className='mobile-issue-img'
              alt='Wound Masterclass issue'
            ></img>
            <span id='about-issue'>
              <br />
              <p>
              Upcoming issue. <br />
                <br />
                Read more about the journal{' '}
                <Link to='/About'>
                  <span
                    className='email-links'
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    here.
                  </span>
                </Link>
                <br />
                <br />
                {/* See our international{' '}
                <Link to='/About'>
                  <span
                    className='email-links'
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    editorial board.
                  </span>
                </Link> */}
                <br />
                <br />
                {/* Please{' '}
                <Link to='/Register'>
                  <span className='email-links' onClick={() => scrollToTop()}>
                    register{' '}
                  </span>
                </Link>{' '}
                with us to get free, full access to the journal. */}
                <br />
                <br />
              </p>
            </span>
          </div>
        </div>
        <div className='mobile-featured-content-container'>
          {/* <h2 className='mobile-content-titles'>
            <span>UPCOMING CONTENT</span>
          </h2> */}
          {/* <div className='mobile-featured-content-box'> */}
            {/* <SwiperMobile  /> */}
          {/* </div> */}
        </div>
      </section>
      {/* <div className='twitter-feed-container'>
        <div className='twitter-feed-box'>
          <TwitterFeed />
        </div>
      </div> */}

      {/* <div className='offer-mobile-container'>
        <img
          src={offer_mobile}
          className='offer-mobile-home'
          alt='register offer'
        ></img>
      </div> */}
    </>
  );
    } else {
      return (
        <>
          <section className='mobile-home-container'>
            {/* <a href='https://ewma.org/' target='_blank'>
              <img
                src={ewmaSize}
                className='ewma-ad-mobile'
                alt='ewma conference ad'
              ></img>
            </a> */}
            <div className='mobile-current-issue-container'>
              <h1 className='mobile-content-titles'>
                <span>UPCOMING ISSUE</span>
              </h1>
              <div className='mobile-current-issue-box'>
              <div style={{position: 'relative', paddingTop: 'max(60%,326px)', height: 0, width: '100%'}}><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox" allowFullScreen="true" style={{position: 'absolute', border: 'none', width: '100%', height: '100%', left: 0, right: 0, top: 0, bottom: 0}} src="https://e.issuu.com/embed.html?backgroundColor=%23000000&d=wound-masterclass-june-2022&hideIssuuLogo=true&hideShareButton=true&u=woundmasterclass" /></div>
              </div>
            </div>
            <div className='mobile-featured-content-container'>
              {/* <h2 className='mobile-content-titles'>
                <span>UPCOMING CONTENT</span>
              </h2> */}
              {/* <div className='mobile-featured-content-box'> */}
                {/* <SwiperMobile  /> */}
              {/* </div> */}
            </div>
          </section>
          {/* <div className='twitter-feed-container'>
            <div className='twitter-feed-box'>
              <TwitterFeed />
            </div>
          </div> */}
    
          {/* <div className='offer-mobile-container'>
            <img
              src={offer_mobile}
              className='offer-mobile-home'
              alt='register offer'
            ></img>
          </div> */}
        </>)
    }
};

export default MobileHome;
