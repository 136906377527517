import '../styles/accounts.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ConsoleView } from 'react-device-detect';

const Accounts = ({
  mobile,
  auth,
  setAuth,
  userFirstName,
  setUserFirstName,
  userLastName,
  setUserLastName,
  userEmail,
  setUserEmail,
  userRole,
  setUserRole,
  userPostalCode,
  setUserPostalCode,
  secureLogin,
  setSecureLogin,
  secureSession,
  setSecureSession,
}) => {
  const navigate = useNavigate();

  const [changeEmail, setChangeEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [changeDetails, setChangeDetails] = useState(false);
  const [detailsChanged, setDetailsChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const [checkCredentials, setCheckCredentials] = useState(false);

  const [confirmCredentials, setConfirmCredentials] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [newEmailValue, setNewEmailValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');

  const [changeEmailAction, setChangeEmailAction] = useState(false);
  const [changePasswordAction, setChangePasswordAction] = useState(false);

  const titleStyle = mobile ? 'mobile-title' : 'events-title';

  const submitHandler = (e) => {
    e.preventDefault();
    console.log('submit');
  };

  const scrollToTop = () => {
    console.log('scroll');
  };

  const changeHandlerEmail = (e) => {
    setEmailValue(e.target.value);
  };

  const changeHandlerPassword = (e) => {
    setPasswordValue(e.target.value);
  };

  const changeHandlerNewEmail = (e) => {
    setNewEmailValue(e.target.value);
  };

  const changeHandlerNewPassword = (e) => {
    setNewPasswordValue(e.target.value);
  };

  useEffect(() => {
    if (checkCredentials) {
      axios
        .post('https://wmcapi.herokuapp.com/api/login', {
          email: emailValue,
          password: passwordValue,
        })
        .then((response) => {
          setConfirmCredentials(true);
          setEmail(emailValue);
          setPassword(passwordValue);
        })
        .catch((err) => {
          setCheckCredentials(false);
          setChangeEmail(false);
          setErrorMessage(true);
          setEmailValue('');
          setPasswordValue('');
        });
    }
  }, [checkCredentials]);

  useEffect(() => {
    if (changeEmailAction) {
      axios
        .patch('https://wmcapi.herokuapp.com/api/amend', {
          col: 'email',
          newValue: newEmailValue,
          email: email,
        })
        .then(() => {
          setChangeEmailAction(false);
          setNewEmailValue('');
          setChangeEmail(false);
          setDetailsChanged(true);
        })
        .catch((err) => {
          setChangeEmailAction(false);
          setNewEmailValue('');
          setChangeEmail(false);
          setErrorMessage(true);
        });
    }
  }, [changeEmailAction]);

  useEffect(() => {
    if (changePasswordAction) {
      axios
        .patch('https://wmcapi.herokuapp.com/api/amendp', {
          password: newPasswordValue,
          email: email,
        })
        .then(() => {
          setNewPasswordValue('');
          setChangePasswordAction(false);
          setChangePassword(false);
          setDetailsChanged(true);
        })
        .catch((err) => {
          if (err) {
            setNewPasswordValue('');
            setChangePasswordAction(false);
            setChangePassword(false);
            setErrorMessage(true);
          }
        });
    }
  }, [changePasswordAction]);

  if (
    auth &&
    !changeEmail &&
    !changePassword &&
    !detailsChanged &&
    !errorMessage
  ) {
    return (
      <div className='accounts-container'>
        <span
          className='accounts-options'
          onClick={() => {
            setChangeEmail(true);
          }}
        >
          Change my email address
        </span>
        <span
          className='accounts-options'
          onClick={() => {
            setChangePassword(true);
          }}
        >
          Change my password
        </span>
        {/* <span
          className='accounts-options'
          onClick={() => {
            setChangeDetails(true);
          }}
        >
          Change my personal details
        </span> */}
      </div>
    );
  } else if (!auth) {
    navigate('/Home');
    return <p>No auth</p>;
  } else if (auth && changeEmail && !confirmCredentials) {
    return (
      <section className='login-container'>
        <div className='error-message'>
          <div className='message-box'>
            <p>
              For security reasons, please enter your current login details.
            </p>
          </div>
        </div>
        {/* <div className={titleStyle}>LOG IN</div> */}
        <div className='login-box'>
          <form className='login-form' id='login' onSubmit={submitHandler}>
            <div>
              <input
                required
                id='login-form-top-input'
                type='text'
                className='login-input'
                placeholder='Email'
                spellCheck='false'
                maxLength='50'
                onChange={changeHandlerEmail}
                value={emailValue}
              ></input>
            </div>
            <div>
              <input
                required
                type='password'
                className='login-input'
                placeholder='Password'
                maxLength='25'
                onChange={changeHandlerPassword}
                value={passwordValue}
              ></input>
            </div>
          </form>
        </div>
        <button
          className='form-button'
          form='login'
          onClick={() => {
            setCheckCredentials(true);
            scrollToTop();
          }}
        >
          CONTINUE TO CHANGE EMAIL
        </button>
        {/* <p className='forgot-question'>
              <span
                onClick={() => {
                  // setResetForm(true);
                }}
              >
                Forgot password?
              </span>
            </p> */}
      </section>
    );
  } else if (auth && changeEmail && confirmCredentials) {
    return (
      <>
        <section className='login-container'>
          <div className='login-box'>
            <form className='login-form' id='login' onSubmit={submitHandler}>
              <div>
                <input
                  required
                  id='login-form-top-input'
                  type='text'
                  className='login-input'
                  placeholder='New Email'
                  spellCheck='false'
                  maxLength='50'
                  onChange={changeHandlerNewEmail}
                  value={newEmailValue}
                ></input>
              </div>
            </form>
          </div>

          <button
            className='form-button'
            form='login'
            onClick={() => {
              setChangeEmailAction(true);
              scrollToTop();
            }}
          >
            CHANGE EMAIL
          </button>
        </section>
      </>
    );
  } else if (auth && changePassword && !changeEmail && !confirmCredentials) {
    return (
      <section className='login-container'>
        <div className='error-message'>
          <div className='message-box'>
            <p>
              For security reasons, please enter your current login details.
            </p>
          </div>
        </div>
        {/* <div className={titleStyle}>LOG IN</div> */}
        <div className='login-box'>
          <form className='login-form' id='login' onSubmit={submitHandler}>
            <div>
              <input
                required
                id='login-form-top-input'
                type='text'
                className='login-input'
                placeholder='Email'
                spellCheck='false'
                maxLength='50'
                onChange={changeHandlerEmail}
                value={emailValue}
              ></input>
            </div>
            <div>
              <input
                required
                type='password'
                className='login-input'
                placeholder='Password'
                maxLength='25'
                onChange={changeHandlerPassword}
                value={passwordValue}
              ></input>
            </div>
          </form>
        </div>
        <button
          className='form-button'
          form='login'
          onClick={() => {
            setCheckCredentials(true);
            scrollToTop();
          }}
        >
          CONTINUE TO CHANGE PASSWORD
        </button>
        {/* <p className='forgot-question'>
              <span
                onClick={() => {
                  // setResetForm(true);
                }}
              >
                Forgot password?
              </span>
            </p> */}
      </section>
    );
  } else if (auth && changePassword && checkCredentials) {
    return (
      <>
        <section className='login-container'>
          <div className='login-box'>
            <form className='login-form' id='login' onSubmit={submitHandler}>
              <div>
                <input
                  required
                  id='login-form-top-input'
                  type='password'
                  className='login-input'
                  placeholder='New Password'
                  spellCheck='false'
                  maxLength='50'
                  onChange={changeHandlerNewPassword}
                  value={newPasswordValue}
                ></input>
              </div>
            </form>
          </div>

          <button
            className='form-button'
            form='login'
            onClick={() => {
              setChangePasswordAction(true);
              scrollToTop();
            }}
          >
            CHANGE PASSWORD
          </button>
        </section>
      </>
    );
  } else if (detailsChanged) {
    setTimeout(() => setDetailsChanged(false), 1700);
    return (
      <>
        <div className='error-message'>
          <div className='message-box'>
            <p>Your account details have been changed.</p>
          </div>
        </div>
      </>
    );
  } else if (errorMessage) {
    setTimeout(() => setErrorMessage(false), 1700);
    return (
      <>
        <div className='error-message'>
          <div className='message-box'>
            <p>Error. Please check your details or try again later.</p>
          </div>
        </div>
      </>
    );
  }
};

export default Accounts;
