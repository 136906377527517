import '../styles/about.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import graphic from '../images/mobile-supplement-graphic.webp';

const About = ({ setNotOptions, mobile }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  const aboutStyle = mobile ? 'mobile-about' : 'desktop-about';

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <section className='about-container'>
      <div className='about-box-container' id='top-about-box'>
        <h1 className='about-title'>ABOUT</h1>
        <div className='about-box'>
          <p className='lorem-ipsum' id='about-p'>
            <br />
            Aesthetics Masterclass is an international aesthetics journal. 
            
            <br />
            <br />
            <br />
            {/* Please{' '}
            <Link to='/Register'>
              <span className='email-links' onClick={() => scrollToTop()}>
                register{' '}
              </span>
            </Link>
            with us to get full access, and to be notified about new and upcoming issues, articles,
            events and learning opportunities. */}
          </p>
        </div>
      </div>
      {/* <div className='about-box-container'>
        <h1 className='about-title'>EDITORIAL BOARD</h1>
        <div className='about-box' id='ed-box'>
          <p className='editorial-board-p'>
            <br />
            <span className='ed-board-name'>
              Miss Negin Shamsian, MBChB MRCS MD
            </span>
            <br />
            <br />
            <span className='editorial-board-member-span'>Chief Editor</span>
            <br />
            <br />
            Plastic and Reconstructive Surgeon <br />
            <br />
            London, United Kingdom
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Prof David Armstrong</span>
            <br />
            <br />
            Professor of Surgery and Director. Southwestern Academic Limb Salvage Alliance (SALSA) at Keck School of Medicine, USC / Director, USC Center to Stream Healthcare in Place
            <br />
            <br /> Los Angeles, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Mr Frank Aviles</span>
            <br />
            <br />
            Wound Care Clinical Coordinator, Natchitoches Regional Medical
            Center
            <br />
            <br /> Natchitoches LA, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'> Prof Dimitri Beeckman</span>
            <br />
            <br />
            Skin and Wound Researcher
            <br />
            <br />
            Professor of Nursing Science, Ghent University (Belgium) and
            Vice-Head of the School of Health Sciences, Örebro University
            (Sweden)
            <br />
            <br /> Ghent, Belgium
            <br />
            <br />
            <br />
            <span className='ed-board-name'> Dr Luis Alejandro Boccalatte</span>
            <br />
            <br />
            Head and Neck Surgeon, Associate Professor Instituto Universitario
            Hospital Italiano <br />
            <br />
            Buenos Aires, Argentina
            <br />
            <br />
            <br />
            <span className='ed-board-name'> Dr Brandon Bosque</span>
            <br />
            <br />
            Foot and Ankle Surgeon <br />
            <br />
            Philadelphia PA, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Kenneth Burhop</span>
            <br />
            <br />
            Life Sciences Advisor and Consultant
            <br />
            <br /> San Diego CA, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Eduardo Camacho</span>
            <br />
            <br />
            Plastic and Reconstructive Surgeon
            <br />
            <br /> Mexico City, Mexico
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Prof Dr C. Can Cedidi</span>
            <br />
            <br />
            Clinic Director for Plastic, Reconstructive & Aesthetic Surgery
            <br />
            <br />
            Bremen, Germany
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Paul Chadwick</span>
            <br />
            <br />
            National Clinical Director, Royal College of Podiatry
            <br />
            <br /> Manchester, United Kingdom
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Windy Cole</span>
            <br />
            <br />
            Director of Wound Care Research, Kent State University of Podiatric
            Medicine
            <br />
            <br />
            National Director of Clinical Safety, Quality and Education,
            Woundtech
            <br />
            <br /> Streetsboro OH, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Ms Kara Couch</span>
            <br />
            <br />
            President-Elect, Association for the Advancement of Wound Care
            <br />
            <br />
            Associate Research Professor of Surgery,
            <br />
            School of Medicine and Health Studies
            <br />
            George Washington University
            <br />
            <br />
            Director, Wound Care Services, The George Washington University
            Hospital
            <br /> 
            <br /> 
            Arlington VA, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Ross D Farhadieh</span>
            <br />
            <br />
            Cosmetic Plastic & Reconstructive Surgeon
            <br />
            <br />
            Sydney, Australia
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Joon Pio Hong</span>
            <br />
            <br />
            Professor of Plastic and Reconstructive Surgery at the University of
            Ulsan College of Medicine and Asan Medical Center <br />
            <br />
            Seoul, South Korea
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Sr Trish Idensohn</span>
            <br />
            <br />
            Wound Nurse Specialist, Consultant and Educator <br />
            <br />
            Durban, South Africa
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Przemysław Lipiński</span>
            <br />
            <br />
            Wound Surgeon, National Representative of Poland in D-Foot
            International <br />
            <br />
            Łódź, Poland
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Mr Tobe Madu</span>
            <br />
            <br />
            Data Scientist, Tissue Analytics (Net Health)
            <br />
            <br /> Baltimore MD, United States
          </p>
          <p className='editorial-board-p'>
            <br />
            <span className='ed-board-name'>Dr M. Mark Melin</span>
            <br />
            <br />
            Wound Care Surgeon, Medical Director of the M Health Wound Healing
            Institute
            <br />
            <br />
            Adjunct Associate Professor, University of Minnesota Surgical
            Department <br />
            <br />
            Mineapolis MN, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>
              Prof Dr Harikrishna K. R. Nair
            </span>
            <br />
            <br />
            President Elect, WUWHS - World Union of Wound Healing Societies
            <br />
            <br />
            President, Asia Pacific Association of Diabetic Limb Problems
            <br />
            <br />
            Kuala Lumpur, Malaysia
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Leo Nherera</span>
            <br />
            <br />
            Director, Global Head of Health Economics & Outcomes Research, Smith
            & Nephew <br />
            <br />
            Fort Worth TX, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Prof Jan Kottner</span>
            <br />
            <br />
            Professor of Nursing Science, Charité Universitätsmedizin Berlin <br />
            <br />
            Berlin, Germany
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Prof Declan Patton</span>
            <br />
            <br />
            Director of Nursing and Midwifery Research and Deputy Director of
            SWaT Research Center, RCSI University of Medicine and Health
            Sciences <br />
            <br />
            Dublin, Ireland
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Dr Mitch Sanders</span>
            <br />
            <br />
            Chief Scientific Officer, Alira Health <br />
            <br />
            Founder of WoundForce LLC (WoundForce.com) and FireFly Innovations LLC (PureMend.com)
            <br />
            <br />
            Boston MA, United States
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Mr Harm Jaap Smit</span>
            <br />
            <br />
            Wound Biologist, Erasmus MC Academy Rotterdam <br />
            <br />
            Rotterdam, Netherlands
            <br />
            <br />
            <br />
            <span className='ed-board-name'>Ms Lian Stoeldraaijers</span>
            <br />
            <br />
            President, Dutch Association of Diabetes Podiatrists<br />
            <br />
            Valkenswaard, Netherlands
          </p>
        </div>
      </div> */}
      {/* <div className='page-graphic-container' id={aboutStyle}>
        <h2 className='mobile-content-titles'>
          <span>UPCOMING CONTENT</span>
        </h2>
        <div className='page-graphic-box'>
          <img src={graphic} className='page-graphic'></img>
        </div>
      </div> */}
    </section>
  );
};

export default About;
