import '../styles/footer.css';
import '../styles/mobile-footer.css';
import wmc_footer from '../images/amc-header.png';
import twitter_icon from '../images/twitter-icon.png';
import facebook_icon from '../images/facebook-icon.png';
import linkedin_icon from '../images/linkedin-icon.png';
import instagram_icon from '../images/instagram-icon.png';
import { Link } from 'react-router-dom';

const Footer = ({ mobile }) => {
  const isMobile = mobile ? 'mobile__footer-body' : 'footer-body';
  const footerBottom = mobile ? 'mobile-footer-bottom' : 'footer-bottom';
  const footerBottomMargin = mobile
    ? 'mobile-footer-bottom-margin'
    : 'footer-bottom-margin';
  const footerImg = mobile ? 'mobile-footer-img' : 'footer-img';
  return (
    <>
      <section className={isMobile}>
        <div className='img-address'>
          <Link
            to='/Home'
            onClick={() => {
              document.documentElement.scrollTop = 0;
            }}
          >
            <img
              src={wmc_footer}
              alt='Wound Masterclass'
              className={footerImg}
            ></img>
          </Link>
          <br />

          <p className='footer-text'>
            Contact Editor:
            <br />
            <br />
            <a href='mailto:editor@woundmasterclass.com'>
              <span className='email-links'>editor@aesthetics-masterclass.com</span>
            </a>
            <br />
            <br />
            Submit an article:
            <br />
            <br />
            <a href='mailto:submissions@woundmasterclass.com'>
              <span className='email-links'>
                submissons@aesthetics-masterclass.com
              </span>
            </a>
            <br />
            <br />
            Commercial inquiries:
            <br />
            <br />
            <a href='mailto:commercial@woundmasterclass.com'>
              <span className='email-links'>
                commercial@aesthetics-masterclass.com
              </span>
            </a>
          </p>
          <div className='footer-smi'>
            <a href='https://www.twitter.com/wound_mc' target='_blank'>
              <img src={twitter_icon} alt='Twitter'></img>
            </a>{' '}
            <a
              href='https://www.facebook.com/people/Wound-Masterclass/100079115137288/'
              target='_blank'
            >
              <img src={facebook_icon} alt='Facebook'></img>
            </a>
            <a
              href='https://www.linkedin.com/company/wound-masterclass/'
              target='_blank'
            >
              <img src={linkedin_icon} alt='Linkedin'></img>
            </a>
            <a
              href='https://www.instagram.com/wound_masterclass'
              target='_blank'
            >
              <img
                id='instagram-icon-footer'
                src={instagram_icon}
                alt='Instagram'
              ></img>
            </a>
          </div>
        </div>

        <div>
          <p className='footer-text'>
            <Link
              to='TermsAndConditions'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Terms and Conditions</span>
            </Link>
            <br />

            <Link
              to='PrivacyPolicy'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Privacy Policy</span>
            </Link>
            <br />
            <Link
              to='/CookiesPolicy'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Cookies Policy</span>
              <br />
            </Link>
          </p>
        </div>
        <div>
          <p className='footer-text'>
            <Link
              to='/About'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>About</span>
            </Link>
            <br />
            <Link
              to='/Issues'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Issues</span>
            </Link>
            <br />
            <Link
              to='/Articles'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Articles</span>
            </Link>
            <br />
            <Link
              to='/Events'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Events</span>
            </Link>
            <br />
            <Link
              to='/About'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Editorial Board</span>
            </Link>
            <br />
            <Link
              to='/Contact'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              <span className='footer-span'>Contact</span>
            </Link>
          </p>
        </div>
        <div>
          <p className='footer-text'>
            <span className='related-journals'>LINKS</span>
            <br />
            <br />
            
            <br />
            <br />
         
          </p>
        </div>
      </section>
      <div className={footerBottomMargin}></div>
      <section className={footerBottom}>
        <span>&copy; Aesthetics Masterclass, 2022</span>
      </section>
    </>
  );
};

export default Footer;
